var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { MEDIA_MOBILE } from './Common';
import { TR_CUBIC } from '../utils/constants';
var LoaderWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  top: 81px;\n  left: 0;\n  width: 100%;\n  height: calc(100vh - 81px);\n  text-align: center;\n  opacity: ", ";\n  background-color: white;\n  pointer-events: none;\n  transition: ", ";\n  z-index: 100;\n\n  video {\n    margin: 0 auto;\n    width: 500px;\n    transition: 0.4s ", ";\n    transform: translateX(", ");\n    opacity: ", ";\n    pointer-events: none;\n  }\n\n  div {\n    padding-top: 20px;\n  }\n\n  ", " {\n    video {\n      display: none;\n    }\n  }\n"], ["\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  top: 81px;\n  left: 0;\n  width: 100%;\n  height: calc(100vh - 81px);\n  text-align: center;\n  opacity: ", ";\n  background-color: white;\n  pointer-events: none;\n  transition: ", ";\n  z-index: 100;\n\n  video {\n    margin: 0 auto;\n    width: 500px;\n    transition: 0.4s ", ";\n    transform: translateX(", ");\n    opacity: ", ";\n    pointer-events: none;\n  }\n\n  div {\n    padding-top: 20px;\n  }\n\n  ", " {\n    video {\n      display: none;\n    }\n  }\n"])), function (props) { return (props.isLoading ? 1 : 0); }, function (props) { return (props.isLoading ? 'none' : "0.4s opacity ".concat(TR_CUBIC)); }, TR_CUBIC, function (props) { return (props.isLoading ? '3px' : '0'); }, function (props) { return (props.isLoading ? 1 : 0); }, MEDIA_MOBILE);
var Loader = function (_a) {
    var isLoading = _a.isLoading, message = _a.message;
    return (<LoaderWrapper data-testid="loader" isLoading={isLoading}>
    <video autoPlay muted loop>
      <source src="/img/loader.mp4" type="video/mp4"/>
    </video>
    <div>{message || 'Loading your experiment...'}</div>
  </LoaderWrapper>);
};
export default Loader;
var templateObject_1;
