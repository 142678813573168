var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import Badge from '@leafygreen-ui/badge';
import TableCell from '@mui/material/TableCell';
import { palette } from '@leafygreen-ui/palette';
import { Body } from '@leafygreen-ui/typography';
export var FONT_FAMILY_FALLBACK = ', "Helvetica Neue", Helvetica, Arial, sans-serif';
export var FONT_FAMILY_SANS = "\"Euclid Circular A\"".concat(FONT_FAMILY_FALLBACK);
export var MEDIA_MOBILE = '@media (max-width: 768px)';
export var TypeLight = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: ", ";\n  color: #777777;\n"], ["\n  font-family: ", ";\n  color: #777777;\n"])), FONT_FAMILY_SANS);
export var TypeBold = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: ", ";\n  border-bottom: ", ";\n  cursor: ", ";\n"], ["\n  font-family: ", ";\n  font-size: ", ";\n  border-bottom: ", ";\n  cursor: ", ";\n"])), FONT_FAMILY_SANS, function (props) { return (props.sizeMed ? '16px' : '13px'); }, function (props) { return (props.isTooltip ? '1px dashed black' : 'none'); }, function (props) { return (props.isTooltip ? 'pointer' : 'default'); });
export var TypeSub = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  color: #777777;\n"], ["\n  font-size: 12px;\n  color: #777777;\n"])));
export var PageTitle = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 15px;\n  word-wrap: break-word;\n  max-width: 500px;\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 28px;\n\n  ", " {\n    font-size: 20px;\n  }\n"], ["\n  margin-top: 0;\n  margin-bottom: 15px;\n  word-wrap: break-word;\n  max-width: 500px;\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: 28px;\n\n  ", " {\n    font-size: 20px;\n  }\n"])), FONT_FAMILY_SANS, function (props) { return (props.isBold ? '700' : '400'); }, MEDIA_MOBILE);
export var Card = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: white;\n  border: 1px solid #e5e5e5;\n  border-radius: 8px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);\n  overflow-x: scroll;\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n  scrollbar-width: none; /* Firefox */\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  background-color: white;\n  border: 1px solid #e5e5e5;\n  border-radius: 8px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);\n  overflow-x: scroll;\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n  scrollbar-width: none; /* Firefox */\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var CardSplit = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var CardSeparator = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 20px 0;\n"], ["\n  margin: 20px 0;\n"])));
export var CellTitle = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-family: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 16px;\n  letter-spacing: 0.4px;\n  color: #21313c;\n"], ["\n  display: flex;\n  align-items: center;\n  font-family: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 16px;\n  letter-spacing: 0.4px;\n  color: #21313c;\n"])), FONT_FAMILY_SANS);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export var CellBadge = styled(function (_a) {
    var isCompressed = _a.isCompressed, children = _a.children, props = __rest(_a, ["isCompressed", "children"]);
    return (<Badge {...props}>{children}</Badge>);
})(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: ", ";\n  font-family: ", ";\n  font-weight: 700;\n"], ["\n  margin-top: ", ";\n  font-family: ", ";\n  font-weight: 700;\n"])), function (_a) {
    var isCompressed = _a.isCompressed;
    return (isCompressed ? '0px' : '5px');
}, FONT_FAMILY_SANS);
export var CellBadgeDetail = styled(function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return <Badge {...props}>{children}</Badge>;
})(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-left: 5px;\n  font-family: ", ";\n  font-weight: 700;\n"], ["\n  margin-left: 5px;\n  font-family: ", ";\n  font-weight: 700;\n"])), FONT_FAMILY_SANS);
export var NoTransformWrapper = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  text-transform: none;\n"], ["\n  text-transform: none;\n"])));
export var PageInner = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: relative;\n  max-width: 1373px;\n  margin: 0 auto;\n  padding: 32px 25px 80px 25px;\n"], ["\n  position: relative;\n  max-width: 1373px;\n  margin: 0 auto;\n  padding: 32px 25px 80px 25px;\n"])));
export var NegativeChangeBox = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background: #ffefef;\n  color: #c11818;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"], ["\n  background: #ffefef;\n  color: #c11818;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"])));
export var PositiveChangeBox = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: #cbeed1;\n  color: #386400;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"], ["\n  background: #cbeed1;\n  color: #386400;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"])));
export var NeutralChangeBox = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background: transparent;\n  color: #000000;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"], ["\n  background: transparent;\n  color: #000000;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 22px;\n  padding: 4px 5px;\n  width: 70px;\n  text-align: center;\n"])));
export var LinkBack = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin-bottom: 40px;\n"], ["\n  margin-bottom: 40px;\n"])));
export var Detail = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  padding-bottom: 40px;\n\n  ", " {\n    flex-direction: column-reverse;\n  }\n"], ["\n  display: flex;\n  padding-bottom: 40px;\n\n  ", " {\n    flex-direction: column-reverse;\n  }\n"])), MEDIA_MOBILE);
export var DetailLeft = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: block;\n  flex: 1;\n"], ["\n  display: block;\n  flex: 1;\n"])));
export var DetailRight = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 180px;\n\n  button {\n    margin: 0;\n  }\n\n  .running-status-select {\n    width: 140px;\n  }\n\n  ", " {\n    margin-bottom: 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 180px;\n\n  button {\n    margin: 0;\n  }\n\n  .running-status-select {\n    width: 140px;\n  }\n\n  ", " {\n    margin-bottom: 20px;\n  }\n"])), MEDIA_MOBILE);
export var DetailRightTop = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 15px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 15px;\n"])));
export var ExperimentMetaWrapper = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 10px;\n\n  ", " {\n    row-gap: 0px;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 10px;\n\n  ", " {\n    row-gap: 0px;\n  }\n"])), MEDIA_MOBILE);
export var DetailMetasOuter = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: inline-block;\n\n  ", " {\n    margin-top: 10px;\n    margin-left: 0;\n  }\n"], ["\n  display: inline-block;\n\n  ", " {\n    margin-top: 10px;\n    margin-left: 0;\n  }\n"])), MEDIA_MOBILE);
export var DetailMetas = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var DetailMeta = styled(Body)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  position: relative;\n  padding-right: 20px;\n  padding-left: 3px;\n  color: #777777;\n  margin-bottom: 15px;\n  font-size: 15px;\n\n  a {\n    color: #009edc;\n  }\n\n  &:after {\n    content: '\u25AA';\n    position: absolute;\n    top: 1px;\n    right: 7px;\n    font-size: 13px;\n  }\n\n  &:last-child:after {\n    content: none;\n  }\n"], ["\n  position: relative;\n  padding-right: 20px;\n  padding-left: 3px;\n  color: #777777;\n  margin-bottom: 15px;\n  font-size: 15px;\n\n  a {\n    color: #009edc;\n  }\n\n  &:after {\n    content: '\u25AA';\n    position: absolute;\n    top: 1px;\n    right: 7px;\n    font-size: 13px;\n  }\n\n  &:last-child:after {\n    content: none;\n  }\n"])));
export var DetailDesc = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  color: #505050;\n  line-height: 1.4em;\n"], ["\n  color: #505050;\n  line-height: 1.4em;\n"])));
export var DetailMeasuredAt = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: 300;\n  color: #777777;\n"], ["\n  font-size: 12px;\n  font-weight: 300;\n  color: #777777;\n"])));
export var GraphOuter = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  padding-top: ", ";\n  text-align: center;\n  padding-left: 0;\n\n  span {\n    /* optical adjustment */\n    padding-left: 10px;\n  }\n"], ["\n  padding-top: ", ";\n  text-align: center;\n  padding-left: 0;\n\n  span {\n    /* optical adjustment */\n    padding-left: 10px;\n  }\n"])), function (props) { return (props.nudgeDown ? '25px' : '15px'); });
export var TableCellAlignTop = styled(TableCell)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  position: relative;\n  vertical-align: top !important;\n"], ["\n  position: relative;\n  vertical-align: top !important;\n"])));
export var TimeSeriesAside = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject([""], [""])));
export var TreatmentLabels = styled.div(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  width: 600px;\n  margin: 0 auto;\n  padding-bottom: 20px;\n  text-align: center;\n\n  hr {\n    position: relative;\n    top: -2px;\n    display: inline-block;\n    border: none;\n    width: 20px;\n    height: 2px;\n    margin: 0;\n    margin-right: 10px;\n  }\n\n  span {\n    font-size: 10px;\n    color: #505050;\n  }\n\n  & > div {\n    border-radius: 3px;\n    display: inline-block;\n    padding: 10px;\n    cursor: pointer;\n    background-color: white;\n    transition: background-color 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97);\n\n    &:hover {\n      background-color: rgba(0, 0, 0, 0.04);\n    }\n  }\n"], ["\n  width: 600px;\n  margin: 0 auto;\n  padding-bottom: 20px;\n  text-align: center;\n\n  hr {\n    position: relative;\n    top: -2px;\n    display: inline-block;\n    border: none;\n    width: 20px;\n    height: 2px;\n    margin: 0;\n    margin-right: 10px;\n  }\n\n  span {\n    font-size: 10px;\n    color: #505050;\n  }\n\n  & > div {\n    border-radius: 3px;\n    display: inline-block;\n    padding: 10px;\n    cursor: pointer;\n    background-color: white;\n    transition: background-color 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97);\n\n    &:hover {\n      background-color: rgba(0, 0, 0, 0.04);\n    }\n  }\n"])));
export var VariantName = styled.div(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  font-size: 12px;\n  margin-top: 14px;\n"], ["\n  font-size: 12px;\n  margin-top: 14px;\n"])));
export var SelectOuter = styled.div(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
export var GreyTableCell = styled(TableCell)(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  background: #f5f5f5;\n  font-family: ", " !important;\n  color: #777777 !important;\n"], ["\n  background: #f5f5f5;\n  font-family: ", " !important;\n  color: #777777 !important;\n"])), FONT_FAMILY_SANS);
export var NoResultsWrapper = styled.div(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var NoResultsText = styled.h2(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 700;\n"], ["\n  font-family: ", ";\n  font-size: 24px;\n  font-weight: 700;\n"])), FONT_FAMILY_SANS);
export var LoadingResultsWrapper = styled.div(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  margin-top: 40px;\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  margin-top: 40px;\n  color: ", ";\n"])), palette.gray.light1);
export var LoadingResultsImg = styled.img(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  animation: loading 3s linear infinite;\n  height: 28px;\n  margin-bottom: 10px;\n  @keyframes loading {\n    0% {\n      transform: rotate(0);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  animation: loading 3s linear infinite;\n  height: 28px;\n  margin-bottom: 10px;\n  @keyframes loading {\n    0% {\n      transform: rotate(0);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])));
export var SampleSizeWrapper = styled.div(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var SampleSizeSpan = styled.span(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  margin-top: -2px;\n  display: flex;\n  align-items: center;\n"], ["\n  margin-top: -2px;\n  display: flex;\n  align-items: center;\n"])));
export var SampleSizeComplete = styled.div(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  font-family: ", ";\n  margin-left: 5px;\n  padding: 3px;\n  background-color: #e4f4e4;\n  border-radius: 4px;\n  color: #00684a;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.2px;\n"], ["\n  font-family: ", ";\n  margin-left: 5px;\n  padding: 3px;\n  background-color: #e4f4e4;\n  border-radius: 4px;\n  color: #00684a;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.2px;\n"])), FONT_FAMILY_SANS);
export var SampleSizeIncomplete = styled.div(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  font-family: ", ";\n  margin-left: 5px;\n  padding: 3px;\n  background-color: #e7eeec;\n  border-radius: 4px;\n  color: #5d6c74;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.2px;\n"], ["\n  font-family: ", ";\n  margin-left: 5px;\n  padding: 3px;\n  background-color: #e7eeec;\n  border-radius: 4px;\n  color: #5d6c74;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.2px;\n"])), FONT_FAMILY_SANS);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41;
